import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';

import { routes } from '@environments/environment';
import { httpInterceptorProviders } from '@shared/services/interceptor';
import { MatPaginatorIntl as MatPaginatorIntl } from '@angular/material/paginator';
import { PaginatorI18n } from '@shared/localization/paginator-I18n';

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      anchorScrolling: 'enabled',
      onSameUrlNavigation: 'reload',
      scrollPositionRestoration: 'top'
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) =>
          new TranslateHttpLoader(http, './assets/locale/', '.json'),
        deps: [HttpClient],
      },
    }),
  ],
  exports: [RouterModule, TranslateModule],
  providers: [
    ...httpInterceptorProviders,
    {
      provide: MatPaginatorIntl,
      deps: [TranslateService],
      useFactory: (translateService: TranslateService) =>
        new PaginatorI18n(translateService).getPaginatorIntl(),
    },
  ],
})
export class AppRoutingModule { }

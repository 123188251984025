<div class="dialog-outline">
  <div class="message-dialog-title-bar">
    <h2 mat-dialog-title class="title"> {{ dialogData.title | translate }}</h2>
    <button mat-icon-button class="button-close" (click)="handleClick($event, dialogData.cancel)">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <mat-divider></mat-divider>

  <div mat-dialog-content> {{ dialogData.message | translate:dialogData.messageParam }} </div>

  <mat-dialog-actions class="dialog-layout" align="center">
    <button [ngClass]="(dialogData.cancel && dialogData.callback) ? 'white' : 'blue'" mat-raised-button
      (click)="handleClick($event, dialogData.cancel)">
      {{ dialogData.cancel.label | translate }}
    </button>
    <button class="blue" *ngIf="dialogData.callback" mat-raised-button
      (click)="handleClick($event, dialogData.callback)">
      {{ dialogData.callback.label | translate }}
    </button>
  </mat-dialog-actions>
</div>